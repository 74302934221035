<template>
  <card-modal
    icon=""
    :title="$t('card.set_aml_status_action.title')"
    context="editAmlProfile"
    @submit="submit"
    @cancel="cancel"
    cancel_text="card.aml_profile.edit_action.button_cancel"
    submit_text="card.aml_profile.edit_action.button_save"
    :submit_working="working"
    :enable_submit="isFormChanged"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-layout row wrap class="edit-aml-profile-action--actions-row">
          <v-flex sm4 xs12 class="edit-aml-profile-action--edit-aml-flag">
            <v-select
              name="aml_flag"
              v-validate="'required'"
              v-model="currentAmlFlag"
              :error-messages="errors.collect('aml_flag')"
              data-vv-name="aml_flag"
              :data-vv-as="$t('card.set_aml_flag_action.aml_flag_error_label')"
              :items="amlFlagOptions"
              item-text="name"
              item-value="abbr"
              :label="$t('card.set_aml_flag_action.aml_flag_label')"
            ></v-select>
          </v-flex>
          <v-flex sm4 xs12 class="edit-aml-profile-action--edit-risk-rating">
            <v-select
              name="risk_rating"
              v-validate="'required'"
              v-model="currentRiskRating"
              :error-messages="errors.collect('risk_rating')"
              data-vv-name="risk_rating"
              :data-vv-as="$t('card.set_risk_rating_action.risk_rating_error_label')"
              :items="riskRatingOptions"
              item-text="name"
              item-value="abbr"
              :label="$t('card.set_risk_rating_action.risk_rating_label')"
            ></v-select>
          </v-flex>
          <v-flex sm4 xs12 v-if="pepsMatched !== 'na'" class="edit-aml-profile-action--edit-peps">
            <v-select
              name="peps"
              v-validate="'required'"
              v-model="currentPeps"
              :error-messages="errors.collect('peps')"
              data-vv-name="peps"
              :data-vv-as="$t('card.set_peps_action.peps_error_label')"
              :items="pepsOptions"
              item-text="name"
              item-value="abbr"
              :label="$t('card.set_peps_action.peps_label')"
            ></v-select>
          </v-flex>
          <v-flex sm4 xs12 v-if="sanctionsMatched !== 'na'" class="edit-aml-profile-action--edit-sanctions" style="padding-left: 0px">
            <v-select
              name="sanctions"
              v-validate="'required'"
              v-model="currentSanctions"
              :error-messages="errors.collect('sanctions')"
              data-vv-name="sanctions"
              :data-vv-as="$t('card.set_sanctions_action.sanctions_error_label')"
              :items="sanctionsOptions"
              item-text="name"
              item-value="abbr"
              :label="$t('card.set_sanctions_action.sanctions_label')"
            ></v-select>
          </v-flex>
        </v-layout>

        <v-layout row>
          <create-aml-note-partial
            v-if="canManageAmlProgram()"
            default-category-id="aml-note"
            default-type-id="general"
            :public-token="publicToken"
            :card-program-key="cardProgramKey"
            :cardholder-key="cardholderKey"
            :account-id="accountId"
            ref="createAmlNote"
            :card-application-id="cardApplicationId"
          />
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import CreateAmlNotePartial from '@/components/system/AML/CreateAmlNotePartial';

export default {
  name: 'edit-aml-profile-action',
  $_veeValidate: {
    validator: 'new',
  },
  components: { CreateAmlNotePartial },
  mixins: [actionErrorTrackable, security],
  props: {
    accountId: {
      type: String,
    },
    cardholderKey: {
      type: String,
      required: true,
    },
    cardApplicationId: {
      type: String,
      required: true,
    },
    publicToken: {
      type: String,
      required: true,
    },
    amlFlag: {
      type: String,
      required: true,
    },
    riskRating: {
      type: String,
      required: true,
    },
    pepsMatched: {
      type: String,
      required: true,
    },
    sanctionsMatched: {
      type: String,
      required: true,
    },
    cardProgramKey: {
      type: String,
      required: true,
    },
  },
  data() {
    const $t = this.$t.bind(this);
    return {
      currentAmlFlag: this.amlFlag,
      currentPeps: this.pepsMatched,
      currentSanctions: this.sanctionsMatched,
      currentRiskRating: this.riskRating,
      amlFlagOptions: [
        { name: $t('code.card.aml_flag.test'), abbr: 'test' },
        { name: $t('code.card.aml_flag.ok'), abbr: 'ok' },
        { name: $t('code.card.aml_flag.of_interest'), abbr: 'of_interest' },
        { name: $t('code.card.aml_flag.monitoring'), abbr: 'monitoring' },
        { name: $t('code.card.aml_flag.high_risk'), abbr: 'high_risk' },
        { name: $t('code.card.aml_flag.potentially_suspicious'), abbr: 'potentially_suspicious' },
        { name: $t('code.card.aml_flag.suspicious'), abbr: 'suspicious' },
        { name: $t('code.card.aml_flag.suspended'), abbr: 'suspended' },
        { name: $t('code.card.aml_flag.fraudulent'), abbr: 'fraudulent' },
        { name: $t('code.card.aml_flag.terminated'), abbr: 'terminated' },
      ],
      riskRatingOptions: [
        { name: $t('code.card.risk_rating.low'), abbr: 'low' },
        { name: $t('code.card.risk_rating.medium'), abbr: 'medium' },
        { name: $t('code.card.risk_rating.high'), abbr: 'high' },
      ],
      pepsOptions: [
        { name: $t('code.aml_profile.pep_matched.negative'), abbr: 'negative' },
        { name: $t('code.aml_profile.pep_matched.positive'), abbr: 'positive' },
        { name: $t('code.aml_profile.pep_matched.possible'), abbr: 'possible' },
      ],
      sanctionsOptions: [
        { name: $t('code.aml_profile.sanctions_matched.negative'), abbr: 'negative' },
        { name: $t('code.aml_profile.sanctions_matched.positive'), abbr: 'positive' },
        { name: $t('code.aml_profile.sanctions_matched.possible'), abbr: 'possible' },
      ],
    };
  },
  computed: {
    ...mapGetters('aml', ['amlStatus']),
    isFormChanged() {
      return Object.keys(this.fields).some((key) => this.fields[key].changed);
    },
    isAmlFlagChanged() {
      if (this.fields.aml_flag) {
        return this.fields.aml_flag.changed;
      }
      return undefined;
    },
    isRiskRatingChanged() {
      if (this.fields.risk_rating) {
        return this.fields.risk_rating.changed;
      }
      return undefined;
    },
    isPepChanged() {
      if (this.fields.peps) {
        return this.fields.peps.changed;
      }
      return undefined;
    },
    isSanctionChanged() {
      if (this.fields.sanctions) {
        return this.fields.sanctions.changed;
      }
      return undefined;
    },
  },
  watch: {
    amlFlag() {
      this.currentAmlFlag = this.amlFlag;
    },
    riskRating() {
      this.currentRiskRating = this.riskRating;
    },
    pepsMatched() {
      this.currentPeps = this.pepsMatched;
    },
    sanctionsMatched() {
      this.currentSanctions = this.sanctionsMatched;
    },
  },
  methods: {
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    resetForm() {
      this.currentAmlFlag = this.amlFlag;
      this.currentPeps = this.pepsMatched;
      this.currentSanctions = this.sanctionsMatched;
      this.currentRiskRating = this.riskRating;
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    async submit() {
      const createAmlNoteRef = this.$refs.createAmlNote;

      const validationComplete = await this.$validator.validateAll();
      const amlNoteValidation = await createAmlNoteRef.$validator.validateAll();

      if (validationComplete && amlNoteValidation) {
        if (this.isPepChanged || this.isSanctionChanged) {
          await this.executeAction(
            {
              action: 'aml/setCardAmlStatus',
              name: 'setCardAmlStatus',
            },
            {
              public_token: this.publicToken,
              aml_status_object: {
                pep_matched: this.currentPeps,
                sanctions_matched: this.currentSanctions,
                status_bar_text: 'card.set_aml_status_action.success',
              },
            },
          );
        }

        if (this.isAmlFlagChanged) {
          await this.executeAction(
            {
              action: 'card/setCardAmlFlag',
              name: 'setCardAmlFlag',
            },
            {
              public_token: this.publicToken,
              card_aml_flag: this.currentAmlFlag,
              card_program_key: this.cardProgramKey,
              cardholder_key: this.cardholderKey,
              account_id: this.accountId,
            },
          );
        }

        if (this.isRiskRatingChanged) {
          await this.executeAction(
            {
              action: 'card/setRiskRating',
              name: 'setRiskRating',
            },
            {
              public_token: this.publicToken,
              risk_rating: this.currentRiskRating,
            },
          );
        }

        if (this.isRiskRatingChanged || this.isPepChanged || this.isSanctionChanged || this.isAmlFlagChanged) {
          await createAmlNoteRef.submit();
        }

        this.success();
      }
    },
  },
};
</script>
