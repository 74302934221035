<template>
  <card :title="`${$t('card.aml_profile.title')}`" class="hc-aml-profile-section">
    <template slot="headerZoneRight">
      <label-text
        :label="$t('card.aml_profile.fatca.title')"
        :value="
          _get(cardApplicant, 'compliance.fatca_crs.foreign_tax_obligations')
            ? $t(`code.aml_profile.fatca.${cardApplicant.compliance.fatca_crs.foreign_tax_obligations}`)
            : $t('code.aml_profile.fatca.false')
        "
        :class-array="[`fatca-${cardApplicant.compliance.fatca_crs.foreign_tax_obligations}`, 'aml-profile--fatca']"
      ></label-text>
      <label-text
        :label="$t('card.aml_profile.third_party.title')"
        :value="
          isThirdPartyDetermination
            ? $t(`code.aml_profile.third_party_determination.${cardApplicant.compliance.third_party_determination.is_third_party}`)
            : $t('code.aml_profile.third_party_determination.false')
        "
        :class-array="[`third-party-${isThirdPartyDetermination}`, 'aml-profile--third-party']"
      ></label-text>
      <label-text
        v-if="showAmlStatus"
        :label="$t('card.aml_profile.peps_match')"
        :value="amlStatus.pep_matched ? $t(`code.aml_profile.pep_matched.${amlStatus.pep_matched}`) : $t('system.na')"
        :class-array="[`pep-matched-${amlStatus.pep_matched}`, 'aml-profile--pep-matched-field']"
      ></label-text>
      <label-text
        v-if="showAmlStatus"
        :label="$t('card.aml_profile.sanctions_match')"
        :value="amlStatus.sanctions_matched ? $t(`code.aml_profile.sanctions_matched.${amlStatus.sanctions_matched}`) : $t('system.na')"
        :class-array="[`sanctions-matched-${amlStatus.sanctions_matched}`, 'aml-profile--sanctions-matched-field']"
      ></label-text>
      <label-text
        :label="$t(`card.aml_profile.aml_flag`)"
        :value="$t(`code.card.aml_flag.${card.card_aml_flag}`)"
        :class-array="['aml-flag', `aml-flag-${card.card_aml_flag}`, 'aml-profile--aml-flag-field']"
      ></label-text>
      <label-text
        :label="$t(`card.aml_profile.risk_rating`)"
        :value="$t(`code.card.risk_rating.${card.cardholder.risk_rating}`)"
        :class-array="['risk-rating', `risk-rating-${card.cardholder.risk_rating}`, 'aml-profile--risk-rating-field']"
      ></label-text>
      <div v-if="showEditAmlProfileFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showEditAmlProfileFeature()" :label="`${$t('card.aml_profile.edit')}`" value="3" class="aml-profile-section--edit">
        <link-secondary-action slot="labelTextLink" @click="showModalEdit"> {{ $t('card.aml_profile.edit_button') }} </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action v-if="showEditAmlProfileFeature()" avoid-rendering :show-modal="showEditModal">
        <edit-aml-profile-action
          :cardholder-key="card.cardholder.key"
          :card-application-id="card.cardholder.card_application_id"
          :public-token="card.public_token"
          :card-program-key="card.card_program_key"
          :account-id="card.account_id"
          :aml-flag="card.card_aml_flag"
          :risk-rating="card.cardholder.risk_rating"
          :peps-matched="amlStatus && amlStatus.pep_matched ? amlStatus.pep_matched : 'na'"
          :sanctions-matched="amlStatus && amlStatus.sanctions_matched ? amlStatus.sanctions_matched : 'na'"
          @close="closeModalEdit"
          @action="reloadPage"
        >
        </edit-aml-profile-action>
      </modal-action>

      <template v-if="showAmlStatus">
        <h4>{{ $t('card.aml_profile.peps_title') }}</h4>
        <v-layout row>
          <label-text
            :label="$t('card.aml_profile.peps_last_verified')"
            :value="amlStatus ? $options.filters.formatDateNoTime(amlStatus.pep_last_assessment_check_date) : $t('system.na')"
          ></label-text>
        </v-layout>
        <v-layout row>
          <card-aml-status-logs-table
            v-if="pepsHistory && pepsHistory.length > 0"
            :items="pepsHistory"
            :card-applicant="cardApplicant"
          ></card-aml-status-logs-table>
        </v-layout>

        <h4>{{ $t('card.aml_profile.sanctions_title') }}</h4>
        <v-layout row>
          <label-text
            :label="$t('card.aml_profile.sanctions_last_verified')"
            :value="amlStatus ? $options.filters.formatDateNoTime(amlStatus.sanctions_last_assessment_check_date) : $t('system.na')"
          ></label-text>
        </v-layout>
        <v-layout row>
          <card-aml-status-logs-table
            v-if="sanctionsHistory && sanctionsHistory.length > 0"
            :items="sanctionsHistory"
            :card-applicant="cardApplicant"
          ></card-aml-status-logs-table>
        </v-layout>
      </template>

      <template v-else>
        <v-alert type="info" :value="true"> {{ $t('aml.aml_status.not_generated') }} </v-alert>
      </template>

      <h4>{{ $t('card.aml_profile.fatca.title') }}</h4>
      <fatca-table v-if="isFatca" :fatca="cardApplicant.compliance.fatca_crs" />
      <v-layout v-if="!isFatca">
        <p>{{ $t('system.na') }}</p>
      </v-layout>

      <h4>{{ $t('card.aml_profile.third_party.title') }}</h4>
      <div v-if="isThirdPartyDetermination" class="data-card--content-zone">
        <v-flex xs12 class="d-inline-flex data-card--content-zone__middle">
          <label-text
            :label="$t('card.aml_profile.third_party.type')"
            :value="cardApplicant.compliance.third_party_determination.third_party.type"
          ></label-text>
          <label-text
            v-if="cardApplicant.compliance.third_party_determination.third_party.type === 'corporate'"
            :label="$t('card.aml_profile.third_party.incorporation_number')"
            :value="cardApplicant.compliance.third_party_determination.third_party.incorporation_number"
          ></label-text>
          <label-text
            v-if="cardApplicant.compliance.third_party_determination.third_party.type === 'corporate'"
            :label="$t('card.aml_profile.third_party.jurisdiction')"
            :value="cardApplicant.compliance.third_party_determination.third_party.jurisdiction"
          ></label-text>
          <label-text
            :label="$t('card.aml_profile.third_party.first_name')"
            :value="_get(cardApplicant, 'compliance.third_party_determination.third_party.first_name')"
          ></label-text>
          <label-text
            :label="$t('card.aml_profile.third_party.last_name')"
            :value="_get(cardApplicant, 'compliance.third_party_determination.third_party.last_name')"
          ></label-text>
          <label-text
            :label="$t('card.aml_profile.third_party.relationship')"
            :value="_get(cardApplicant, 'compliance.third_party_determination.third_party.relationship')"
          ></label-text>
          <label-text
            :label="$t('card.aml_profile.third_party.occupation')"
            :value="_get(cardApplicant, 'compliance.third_party_determination.third_party.occupation')"
          ></label-text>
          <label-text
            :label="$t('card.aml_profile.third_party.date_of_birth')"
            :value="_get(cardApplicant, 'compliance.third_party_determination.third_party.date_of_birth')"
          ></label-text>
          <label-text :label="$t('card.aml_profile.third_party.address')" :value="fullAddress"></label-text>
        </v-flex>
      </div>
      <v-layout v-else>
        <p>{{ $t('system.na') }}</p>
      </v-layout>
    </template>
  </card>
</template>

<script>
import CardAmlStatusLogsTable from './CardAmlStatusLogsTable';
import EditAmlProfileAction from '../actions/EditAmlProfileAction';
import _ from 'lodash';
import { actionErrorTrackable, security } from '@/mixins';
import _get from 'lodash/get';

export default {
  name: 'aml-profile',
  mixins: [actionErrorTrackable, security],
  components: { CardAmlStatusLogsTable, EditAmlProfileAction },
  props: {
    card: {
      type: Object,
      required: false,
    },
    cardApplicant: {
      type: Object,
      required: false,
    },
    amlStatus: {
      type: Object,
      required: false,
    },
    amlStatusLogs: {
      type: Array,
      required: false,
    },
    amlStatusLogsAndAssessmentChecks: {
      type: Object,
      required: true,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      showEditModal: false,
    };
  },
  computed: {
    showAmlStatus() {
      return !_.isEmpty(this.amlStatus);
    },
    isFatca() {
      return _get(this.cardApplicant, 'compliance.fatca_crs.foreign_tax_obligations', false);
    },
    isThirdPartyDetermination() {
      return _get(this.cardApplicant, 'compliance.third_party_determination.is_third_party', false);
    },
    fullAddress() {
      return `${this.cardApplicant.compliance.third_party_determination.third_party.address.state}, ${this.cardApplicant.compliance.third_party_determination.third_party.address.city}, ${this.cardApplicant.compliance.third_party_determination.third_party.address.state}, ${this.cardApplicant.compliance.third_party_determination.third_party.address.country}, ${this.cardApplicant.compliance.third_party_determination.third_party.address.zip}`;
    },
    pepsHistory() {
      if (this.amlStatusLogsAndAssessmentChecks.pepLogs) {
        return _.map(this.amlStatusLogsAndAssessmentChecks.pepLogs, (log) => {
          const logForDisplay = {};
          logForDisplay.created = log.created;
          logForDisplay.user = log.user;
          logForDisplay.change = log.change;
          logForDisplay.list = log.list || null;
          logForDisplay.view_details = log.view_details || null;
          logForDisplay.type = 'peps';

          return logForDisplay;
        });
      }
      return undefined;
    },
    sanctionsHistory() {
      if (this.amlStatusLogsAndAssessmentChecks.sanctionLogs) {
        return _.map(this.amlStatusLogsAndAssessmentChecks.sanctionLogs, (log) => {
          const logForDisplay = {};
          logForDisplay.created = log.created;
          logForDisplay.user = log.user;
          logForDisplay.change = log.change;
          logForDisplay.list = log.list || null;
          logForDisplay.view_details = log.view_details || null;
          logForDisplay.type = 'sanctions';

          return logForDisplay;
        });
      }
      return undefined;
    },
  },
  methods: {
    _get,
    showModalEdit() {
      this.showEditModal = true;
    },
    closeModalEdit() {
      this.showEditModal = false;
    },
    reloadPage() {
      this.showEditModal = false;
    },
    showEditAmlProfileFeature() {
      return this.canManageAmlProgram();
    },
  },
};
</script>
