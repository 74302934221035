import Vue from 'vue';

const service = {
  async getAccount(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/account/v1/accounts/${id}`);
    return response.data.data;
  },
  async update(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/account/v1/accounts/${id}`, payload);
    return response.data.data;
  },
  async setAccountAmlFlag(id, amlFlag) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/account/v1/accounts/${id}/aml-flag`, {
      aml_flag: amlFlag,
    });
    return response.data.data.aml_flag;
  },
  async setAccountRiskRating(id, risk_rating) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/account/v1/accounts/${id}/risk-rating`, {
      risk_rating,
    });
    return response.data.data.risk_rating;
  },
  async getOrder(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/account/v1/orders/${id}`);
    return response.data.data;
  },
  async getCorporateOrder(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/account/v1/corporate-orders/${id}`);
    return response.data.data;
  },
  async getCorporateOrderInvoice(id, lang) {
    const queryStrings = [];
    if (lang) {
      queryStrings.push(['lang', lang]);
    }
    const params = new URLSearchParams(queryStrings);

    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/account/v1/corporate-orders/${id}/invoice?${params}`, {
      responseType: 'blob',
    });
    return { fileContent: response.data, filename: extractFileNameFromHeaders(response.headers) };
  },
  async getOrderInvoice(id, lang) {
    const queryStrings = [];
    if (lang) {
      queryStrings.push(['lang', lang]);
    }
    const params = new URLSearchParams(queryStrings);

    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/account/v1/orders/${id}/invoice?${params}`, {
      responseType: 'blob',
    });
    return { fileContent: response.data, filename: extractFileNameFromHeaders(response.headers) };
  },
  async getConsumerGiftCardOrderInvoice(id, lang) {
    const queryStrings = [];
    if (lang) {
      queryStrings.push(['lang', lang]);
    }
    const params = new URLSearchParams(queryStrings);

    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/account/v1/consumer-gift-card-orders/${id}/invoice?${params}`,
      {
        responseType: 'blob',
      },
    );
    return { fileContent: response.data, filename: extractFileNameFromHeaders(response.headers) };
  },
  async getConsumerGiftCardOrder(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/account/v1/consumer-gift-card-orders/${id}`);
    return response.data.data;
  },
  async listConsumerGiftCardOrdersByAccountId(accountId, page, limit) {
    const queryStrings = [];
    if (page) {
      queryStrings.push(['page', page]);
    }
    if (limit) {
      queryStrings.push(['limit', limit]);
    }
    const params = new URLSearchParams(queryStrings);

    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/account/v2/accounts/${accountId}/consumer-gift-card-orders?${params}`,
    );
    return response.data.data;
  },
  async getAccountByCardholderId(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/account/v1/accounts/cardholders/${id}`);
    return response.data.data;
  },
  async listAccounts() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/account/v1/accounts`);
    return response.data.data.items;
  },
  async listInstantCardsByAccountId(accountId, page, limit) {
    const queryStrings = [];

    if (page) {
      queryStrings.push(['page', page]);
    }

    if (limit) {
      queryStrings.push(['limit', limit]);
    }

    queryStrings.push(['card_program_type', 'instant']);

    const params = new URLSearchParams(queryStrings);

    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/accounts/${accountId}/cards?${params}`);

    return response.data.data;
  },
  async listLoadableCardsByAccountId(accountId, page, limit = 100) {
    const queryStrings = [];

    if (page) {
      queryStrings.push(['page', page]);
    }
    if (limit) {
      queryStrings.push(['limit', limit]);
    }

    const params = new URLSearchParams(queryStrings);

    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/accounts/${accountId}/loadable-cards?${params}`);

    return response.data.data;
  },
  async getResetPasswordLink({ email, preferred_language }) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/id/v2/password-reset-link`, { email, preferred_language });
    return response.data.data;
  },
  async manualVerifyEmail({ id, email }) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/account/v1/accounts/${id}/manual-verify-email`, { email });
    return response.data.data;
  },
  async changeEmail({ id, email, doAutoVerify, language }) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/account/v1/accounts/${id}/change-email`, {
      email,
      doAutoVerify,
      language,
    });
    return response.data.data;
  },
  async createAccount(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/account/v1/accounts`, payload);
    return response.data.data;
  },
  async searchAccounts(page, limit, keyword) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/account-search/v1/accounts/search', page, limit, keyword)}`,
    );
    return response.data.data;
  },
  async updateAccount(id, params) {
    const payload = _.pick(params, ['language', 'first_name', 'last_name', 'gender', 'phone', 'mobile', 'year_of_birth', 'address', 'settings']);

    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/account/v1/accounts/${id}`, payload);
    return response.data.data;
  },
  async deactivateAccount(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/v1/accounts/${id}/deactivate`, payload);
    return response.data.data;
  },
  async createAccountNote(account_id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/account/v1/accounts/${account_id}/notes`, payload);
    return response.data.data;
  },
  async deleteAccountNote(account_id, note_id, payload) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/account/v1/accounts/${account_id}/notes/${note_id}/delete`,
      payload,
    );
    return response.data.data;
  },
  async listAccountNotes(page, limit, account_id) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/account/v1/accounts/${account_id}/notes`, page, limit)}`,
    );
    return response.data.data;
  },
};
function generatePath(startPath, page, limit, keyword) {
  let path = startPath;
  if (page || limit || keyword) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

function extractFileNameFromHeaders(headers) {
  return headers['content-disposition']
    .split(';')
    .find((n) => n.includes('filename='))
    .replace('filename=', '')
    .trim()
    .slice(1, -1);
}

export default service;
