<template>
  <card-data id="user-informations">
    <template slot="headerZoneLeft">
      <v-icon>fal fa-user</v-icon>
      <h3>{{ $t('user.details.user_information_section.title') }}</h3>
    </template>

    <template slot="headerZoneRight">
      <label-text :label="`${$t('user.details.user_information_section.id')}`" :value="account.id">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ account.id }}
            <button class="clipboard" v-clipboard:copy="account.id ? account.id : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('user.details.user_information_section.email')}`" :value="account.contact.email.value">
        <p slot="labelTextLink" class="email-status">
          <b :class="`status-${account.contact.email.status}`">{{ account.contact.email.value }}</b>
          <button class="clipboard" v-clipboard:copy="account.contact.email.value">
            <v-icon small>file_copy</v-icon>
          </button>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <!-- TODO (JG) Waiting for SG return.
        <label-text :label="`${$t('user.details.user_information_section.aml_flag')}`" :value="account.aml_flag">
        <p slot="labelTextLink" class="aml-flag" :class="`aml-flag-${account.aml_flag}`">
          <b>{{ $t(`user.aml_flag.${account.aml_flag}`) }}</b>
        </p>
      </label-text>
      <div class="card-header-divider&#45;&#45;vertical hidden-xs-only"><v-divider vertical></v-divider></div>-->
      <label-text
        :label="$t(`user.details.user_information_section.status.label`)"
        :value="$t(`user.details.user_information_section.status.type.${account.status}`)"
        :class-array="[`user-status-${account.status}`]"
      ></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="`${$t('user.details.user_information_section.created')}`"
        :value="account.creation_date | formatShortDateInverted | orNotAvailable"
      />
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <link-secondary-action slot="labelTextLink">
        <button-secondary-actions class="application-info--actions" :text="$t(`system.actions`)" :actions="actions"> </button-secondary-actions>
      </link-secondary-action>

      <modal-action :show-modal="activeModal !== null">
        <edit-account-action
          :account="account"
          v-if="activeModal === 'edit_account'"
          @close="closeModal"
          @action="resetPageState('user.edit_action.success')"
        />
        <verify-email-action v-if="activeModal === 'email_verification'" :user="account" @close="closeModal" @action="resetPageState" />
        <change-email-action v-if="activeModal === 'change_email'" :user="account" @close="closeModal" @action="resetPageState" />
        <generate-reset-password-link-action v-if="activeModal === 'generate_reset_password_link'" @close="closeModal" @action="resetPageState" />
        <deactivate-account-action
          :user="account"
          :open-purse-available-balance="openPurse.available_amount"
          v-if="activeModal === 'deactivate_account'"
          @close="closeModal"
          @action="resetPageState"
        />
      </modal-action>
    </template>
    <template slot="contentZoneUpper">
      <label-text
        :label="$t(`user.details.user_information_section.label.gender`)"
        :value="$t(`gender.${account.gender}`)"
        :class-array="['larger-text']"
      ></label-text>

      <label-text
        :label="$t('user.details.user_information_section.label.first_name')"
        :value="account.first_name"
        :class-array="['larger-text']"
      ></label-text>

      <label-text
        :label="$t('user.details.user_information_section.label.last_name')"
        :value="account.last_name"
        :class-array="['larger-text']"
      ></label-text>
      <label-text
        :label="$t('user.details.user_information_section.label.year_of_birth')"
        :value="account.year_of_birth"
        :class-array="['larger-text']"
      ></label-text>
      <label-text
        :label="$t(`user.details.user_information_section.address`)"
        :value="this.account.address | addressOneLine"
        :class-array="['larger-text card-info--address-field']"
      ></label-text>
    </template>

    <template slot="contentZoneMiddle">
      <label-text :label="$t(`user.details.user_information_section.label.phone`)" :value="account.phone" />
      <label-text :label="$t(`user.details.user_information_section.label.mobile`)" :value="account.mobile" />
      <label-text :label="$t(`user.details.user_information_section.label.language`)" :value="account.language" />
      <label-text :label="`${$t('user.details.user_information_section.label.sponsor_id')}`" :value="account.sponsor_partner_id" />
      <label-text
        v-if="account.settings"
        :label="`${$t('user.details.user_information_section.label.settings_alerts')}`"
        :value="account.settings.alerts | boolean"
        :class-array="[`bool-${account.settings.alerts}`]"
      />
    </template>

    <template slot="footerZone">
      <v-layout row wrap class="card-info-footer-zone--inner">
        <v-flex xs12 md6 class="card-info-footer-zone--left">
          <v-btn align-bottom small round outline color="primary" class="dashboard-link-btn">
            <router-link target="_blank" :to="{ name: 'card_activities_dashboard', query: { user_id: accountId, timerange: '~' } }">
              {{ $t('card.card_info.bi.activity') }}
            </router-link>
            <v-icon small>navigate_next</v-icon>
          </v-btn>
          <v-btn align-bottom small round outline color="primary" class="dashboard-link-btn">
            <router-link target="_blank" :to="{ name: 'debits_credits_dashboard', query: { user_id: accountId, timerange: '~' } }">
              {{ $t('card.card_info.bi.debits_credits') }}
            </router-link>
            <v-icon small>navigate_next</v-icon>
          </v-btn>
          <v-btn align-bottom small round outline color="primary" class="dashboard-link-btn">
            <router-link target="_blank" :to="{ name: 'transactions_dashboard', query: { user_id: accountId, timerange: '~' } }">
              {{ $t('card.card_info.bi.transactions') }}
            </router-link>
            <v-icon small>navigate_next</v-icon>
          </v-btn>

          <v-btn align-bottom small round outline color="primary" class="dashboard-link-btn">
            <router-link target="_blank" :to="{ name: 'fraud_analysis_dashboard', query: { user_id: accountId, timerange: '~' } }">
              {{ $t('card.card_info.bi.fraud_analysis') }}
            </router-link>
            <v-icon small>navigate_next</v-icon>
          </v-btn>
        </v-flex>
        <v-flex 12 md6 class="card-info-footer-zone--right"> </v-flex>
      </v-layout>
    </template>
  </card-data>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import Vue from 'vue';
import LinkSecondaryAction from '@/components/system/Links/LinkSecondaryAction';
import VerifyEmailAction from '@/components/user/actions/VerifyEmailAction';
import ChangeEmailAction from '@/components/user/actions/ChangeEmailAction';
import GenerateResetPasswordLinkAction from '@/components/user/actions/GenerateResetPasswordLinkAction';
import EditAccountAction from '@/components/user/actions/EditAccountAction';
import DeactivateAccountAction from '@/components/user/actions/DeactivateAccountAction.vue';
import AccountStatus from '@/enums/account-status';

export default {
  name: 'user-information',
  components: {
    DeactivateAccountAction,
    VerifyEmailAction,
    ChangeEmailAction,
    LinkSecondaryAction,
    GenerateResetPasswordLinkAction,
    EditAccountAction,
  },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    const $t = this.$t.bind(this);
    return {
      showEmailVerificationModal: false,
      activeModal: null,
      actions: [
        {
          slotName: 'editAccount',
          name: $t(`user.details.user_information_section.button_edit_account`),
          action: () => (this.activeModal = 'edit_account'),
          isAvailable: () => this.isAgentInternal(),
          isDisabled: () => this.accountIsDeactivated || this.accountIsAnonymous,
        },
        {
          slotName: 'emailverification',
          name: $t(`user.details.user_information_section.button_email_verification`),
          action: () => (this.activeModal = 'email_verification'),
          isAvailable: () => this.isAgentInternal() && !this.emailVerified,
          isDisabled: () => this.accountIsDeactivated || this.accountIsAnonymous,
        },
        {
          slotName: 'changeEmail',
          name: $t(`user.details.user_information_section.button_change_email`),
          action: () => (this.activeModal = 'change_email'),
          isAvailable: () => this.isAgentInternal(),
          isDisabled: () => this.accountIsDeactivated || this.accountIsAnonymous,
        },
        {
          slotName: 'generateresetpswdlink',
          name: $t(`user.details.user_information_section.button_generate_reset_pswd_link`),
          action: () => {
            this.executeAction(
              { action: 'account/generateResetPasswordLink', name: 'generateResetPasswordLink' },
              { preferred_language: this.account.language, email: this.account.contact.email.value },
            );
            this.activeModal = 'generate_reset_password_link';
          },
          isAvailable: () => this.isAgentInternal(),
          isDisabled: () => this.accountIsDeactivated || this.accountIsAnonymous,
        },
        {
          slotName: 'deactivateAccount',
          name: $t(`user.details.user_information_section.button_deactivate_account`),
          action: () => (this.activeModal = 'deactivate_account'),
          isAvailable: () => this.isAgentInternal(),
          isDisabled: () => this.accountIsDeactivated,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('purse', ['openPurse']),
    ...mapGetters('account', ['account', 'accountId']),
    ...mapGetters('ui', ['currentLocale']),
    emailVerified() {
      return this.account.contact.email.status === 'verified';
    },
    accountIsAnonymous() {
      return !!this.account.anonymous;
    },
    accountIsDeactivated() {
      return this.account.status === AccountStatus.DEACTIVATED;
    },
  },
  methods: {
    closeModal() {
      this.activeModal = null;
    },
    async resetPageState(snackBarMessage) {
      if (snackBarMessage) {
        await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: snackBarMessage });
      }
      this.activeModal = null;
    },
    reloadPage() {
      this.showEmailVerificationModal = false;
    },
    async goToCardFromCardholderId(cardholderId) {
      const card = await Vue.prototype.$services.card.getLatestCardByCardholderId(cardholderId);
      if (card) {
        this.$router.push({ name: 'card', params: { cardPublicToken: card.public_token } });
      }
    },
  },
};
</script>
